import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PassingIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No path posts found. Add markdown posts to "content/paths" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <ol className="post-list">
        {posts.map(post => {
          let image;

          if (post.frontmatter.pathImage) {
            image = ( <Img fluid={{...post.frontmatter.pathImage.childImageSharp.fluid, aspectRatio: 3 / 2}} /> )
          } else {
            image = (<div>No Image Placeholder</div>);
          }

          return (
            <li key={post.fields.slug} className="post-list__item">
              <Link to={post.fields.slug}>
                <article >
                  {image}
                </article>
              </Link>
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default PassingIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: ASC}, filter: {frontmatter: {description: {eq: null}}}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          pathImage {
            relativePath
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
